import { stringx } from "@deathbycode/fp-ts-ext"
import { isProduction } from "app/common/config/node"
import { splitOnSlash } from "app/common/fp-ts/array"
import { when } from "app/common/utilities/function"
import { empty, setParam } from "app/common/utilities/querystring"
import { CanadianLocales } from "app/common/utilities/types"
import { DelimitedFiltersC, FacetFilter } from "app/search/codecs"
import { LocaleD } from "db"
import { array, number, option } from "fp-ts"
import { flow, pipe } from "fp-ts/function"

const routePrefix = (locale?: string) => (locale === LocaleD.fr_ca ? `/${CanadianLocales.Fr}` : "")

type LocaleOnlyArgs = { locale?: LocaleD }

type AboutArgs = LocaleOnlyArgs

type ArticleArgs = { number?: number; slug: string } & LocaleOnlyArgs

type BrandArgs = { slug: string } & LocaleOnlyArgs

type CategoryArgs = {
  filter?: FacetFilter[]
  page?: number
  slugPath?: string[] | undefined
} & LocaleOnlyArgs

type ContactArgs = LocaleOnlyArgs

type HomeArgs = LocaleOnlyArgs

type PrivacyPolicyArgs = LocaleOnlyArgs

type TermsOfUseArgs = LocaleOnlyArgs

type SearchArgs = { filter?: FacetFilter[]; page?: number; q?: string } & LocaleOnlyArgs

const prefixWith = (prefix: string) => (str: string) => `${prefix}${str}`

const prefixWithQuestionMark = prefixWith("?")

export const RouteMgr = {
  About: ({ locale }: AboutArgs = {}) => `${routePrefix(locale)}/about`,

  ArticlePage: ({ locale, number, slug }: ArticleArgs) =>
    `${routePrefix(locale)}/${[`articles`, slug, ...(number && number > 1 ? [number] : [])].join(
      "/"
    )}`,

  BrandPage: ({ locale, slug }: BrandArgs) =>
    `${routePrefix(locale)}/${[`brands`, slug].join("/")}`,

  CategoryPage: ({ filter = [], locale, page, slugPath }: CategoryArgs = {}) => {
    const { left, right } = pipe(
      filter ?? [],
      array.partition((f) => f.field === "category")
    )

    const sp = pipe(
      right,
      array.head,
      option.map(flow((f) => f.value, splitOnSlash)),
      option.alt(() => pipe(slugPath, option.fromNullable)),
      option.getOrElse(() => [] as string[])
    )

    return pipe(
      empty,
      when(() => array.isNonEmpty(left))(setParam("filter")(DelimitedFiltersC.encode(left ?? []))),
      String,
      when(stringx.isNonEmpty)(prefixWithQuestionMark),
      (search) =>
        `${routePrefix(locale)}/${[`categories`, ...sp, ...(page && page > 1 ? [page] : [])].join(
          "/"
        )}${search}`
    )
  },

  Contact: ({ locale }: ContactArgs = {}) => `${routePrefix(locale)}/contact`,

  Home: ({ locale }: HomeArgs = {}) => `${routePrefix(locale)}`,

  PrivacyPolicy: ({ locale }: PrivacyPolicyArgs = {}) => `${routePrefix(locale)}/privacy-policy`,

  Search: ({ filter = [], locale, page, q }: SearchArgs) => {
    return pipe(
      empty,
      when(() => number.isNumber(page) && page > 1)(setParam("page")(page?.toString() ?? "")),
      when(() => array.isNonEmpty(filter))(
        setParam("filter")(DelimitedFiltersC.encode(filter ?? []))
      ),
      String,
      when(stringx.isNonEmpty)((s) => `?${s}`),
      (search) => `${routePrefix(locale)}/search/${q}${search}`
    )
  },

  TermsOfUse: ({ locale }: TermsOfUseArgs = {}) => `${routePrefix(locale)}/terms-of-use`,
}

const baseUrl = isProduction ? "https://norther.ca" : "http://localhost:3000"

export const UrlMgr = {
  About: ({ locale }: AboutArgs = {}) => baseUrl.concat(RouteMgr.About({ locale })),

  ArticlePage: ({ locale, number, slug }: ArticleArgs) =>
    baseUrl.concat(RouteMgr.ArticlePage({ locale, number, slug })),

  BrandPage: ({ locale, slug }: BrandArgs) => baseUrl.concat(RouteMgr.BrandPage({ locale, slug })),

  CategoryPage: ({ locale, page: number, slugPath }: CategoryArgs = {}) =>
    baseUrl.concat(RouteMgr.CategoryPage({ locale, page: number, slugPath })),

  Contact: ({ locale }: ContactArgs = {}) => baseUrl.concat(RouteMgr.Contact({ locale })),

  Home: ({ locale }: HomeArgs = {}) => baseUrl.concat(RouteMgr.Home({ locale })),

  PrivacyPolicy: ({ locale }: PrivacyPolicyArgs = {}) =>
    baseUrl.concat(RouteMgr.PrivacyPolicy({ locale })),

  Search: ({ filter, locale, q }: SearchArgs) =>
    baseUrl.concat(RouteMgr.Search({ filter, locale, q })),

  TermsOfUse: ({ locale }: TermsOfUseArgs = {}) => baseUrl.concat(RouteMgr.TermsOfUse({ locale })),
}
