import { BrandSearchFilterC } from "app/search/codecs/BrandSearchFilterC"
import { LocalizedSearchC } from "app/search/codecs/LocalizedSearchC"
import { withDelimited } from "app/search/codecs/withDelimited"
import { identity } from "fp-ts/function"
import * as t from "io-ts"
import { optionFromNullable, withFallback } from "io-ts-types"

export const SearchParamsC = t.intersection([
  LocalizedSearchC,
  t.type({
    filter: withFallback(withDelimited(BrandSearchFilterC), []),
    orderBy: optionFromNullable(t.array(t.string)),
  }),
])

export type SearchParams = t.TypeOf<typeof SearchParamsC>

export const createSearchParams: (params: SearchParams) => SearchParams = identity
