import { FilterName, FilterNameC } from "app/search/codecs/FilterNameC"
import { either } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"

export type StringEqualsMultipleFilter = {
  field: FilterName
  value: Array<string>
}

export const StringEqualsMultipleFilterC = new t.Type<StringEqualsMultipleFilter, string>(
  "StringEqualsMultipleFilterC",
  (u): u is StringEqualsMultipleFilter =>
    t.string.is((u as StringEqualsMultipleFilter)?.field) &&
    t.array(t.string).is((u as StringEqualsMultipleFilter)?.value),
  (u, c) => {
    return t.string.is(u) && u.split("==").length === 2 && u.includes(",")
      ? pipe(
          u.split("==")[0] ?? "",
          (field) => FilterNameC.validate(field, c),
          either.chain((fn) =>
            t.success({
              field: fn,
              value: u.split("==")[1]?.split(",") ?? [],
            })
          )
        )
      : t.failure(u, c)
  },
  (a) => `${a.field}==${a.value.join(",")}`
)
