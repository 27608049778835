import { ValueOf } from "app/common/utilities/types"
import { LocaleD } from "db"
import { option } from "fp-ts"

export type OgLocale = { alternate: LocaleD; locale: LocaleD }

// ts-prune-ignore-next
export const BasicMetaData = {
  Image: "og:image",
  Title: "og:title",
  Type: "og:type",
  Url: "og:url",
} as const

type BasicMetaData = ValueOf<typeof BasicMetaData>

export const OgType = {
  Article: "article",
  Website: "website",
} as const

type OgType = ValueOf<typeof OgType>

export interface OpenGraphMetadata {
  description: string
  locale: OgLocale
  siteName: string
  title: string
  type: option.Option<OgType>
  url: string
}
