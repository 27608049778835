import { Head } from "blitz"
import React from "react"

interface GoogleAnalyticsProps {
  measurementId: string | undefined
}

export const GoogleAnalyticsHead = ({ measurementId }: GoogleAnalyticsProps) => (
  <Head>
    <script async id="ga1" src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
             window.dataLayer = window.dataLayer || [];
             function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
             gtag('config', '${measurementId}', {
               page_path: window.location.pathname,
             });
           `,
      }}
      id="ga2"
    />
  </Head>
)
