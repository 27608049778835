import { LocaleD } from "@prisma/client"
import { LocaleDC } from "app/common/decoders/locale"
import { includes, splitOnSlash } from "app/common/fp-ts/array"
import { isNotNullish } from "app/common/fp-ts/predicate"
import { RouteMgr } from "app/common/routes"
import autocomplete from "app/search/queries/autocomplete"
import { useQuery, useRouter } from "blitz"
import { useCombobox } from "downshift"
import { array, either, option } from "fp-ts"
import { pipe } from "fp-ts/function"
import useTranslation from "next-translate/useTranslation"
import React, { useEffect, useState } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import { tw } from "twind"

export const SearchForm = () => {
  const router = useRouter()

  const { t } = useTranslation("common")

  const [q, setQ] = useState((router.query.q as string) ?? "")

  const [autoCompleteResponse] = useQuery(
    autocomplete,
    {
      locale: pipe(
        router.locale,
        LocaleDC.decode,
        either.getOrElse(() => LocaleD.en_ca)
      ),
      q,
    },
    { enabled: !!q, suspense: false }
  )

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    inputValue: q,
    items: pipe(
      autoCompleteResponse,
      option.fromNullable,
      option.map(array.map((autocompleteItem) => autocompleteItem.queryPlusText)),
      option.getOrElse<string[]>(() => [])
    ),
    onInputValueChange: ({ inputValue }) => {
      if (isNotNullish(inputValue)) {
        setQ(inputValue)
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      const q = selectedItem ?? ""
      router.push(RouteMgr.Search({ q }))
      setQ(q)
    },
  })

  useEffect(() => {
    if (!pipe(router.asPath, splitOnSlash, includes("search"))) {
      setQ("")
    }
  }, [router.asPath])

  return (
    <form
      className={tw(
        `flex items-center`,
        `fixed sm:relative bottom-0 left-0 right-0`,
        `border(& 0 sm:2)`,
        `bg(gray-200 sm:transparent)`,
        `z-20`,
        `text(2xl gray-600 lg)`
      )}
      onSubmit={(e) => {
        e.preventDefault()
        
        router.push(RouteMgr.Search({ q }))
      }}
    >
      <div {...getComboboxProps({ className: tw(`flex(& row(& sm:reverse))`, `w-full`) })}>
        <label {...getLabelProps({ className: tw(`fixed -left-full`), htmlFor: "search-input" })}>
          Search
        </label>
        <button
          aria-label="Search"
          className={tw(`px(4 sm:2) py(2 sm:1)`, `hover:text(sm:blue-500)`)}
          type="submit"
        >
          <AiOutlineSearch />
        </button>
        <input
          {...getInputProps({
            id: "q",
            type: "search",
          })}
          className={tw(
            `flex-1`,
            `outline-none`,
            `px(4 sm:2) py(2 sm:1)`,
            `my(2 sm:0) mr(2 sm:0)`,
            `rounded-lg`,
            `bg(white opacity-20 sm:transparent)`,
            `text(sm gray-900)`,
            `min-w-0 w-[26rem]`
          )}
          id="search-input"
          placeholder={t("search-blurb")}
        />
      </div>

      <ul
        {...getMenuProps()}
        className={tw(
          `absolute left-0 right-0 bottom(full sm:auto) top(sm:full)`,
          `z-40 `,
          `text-gray-900`,
          `bg-white`,
          `border(& gray-200)`,
          `shadow`,
          !(isOpen && autoCompleteResponse?.length) && `hidden`
        )}
      >
        {isOpen &&
          pipe(
            autoCompleteResponse,
            option.fromNullable,
            option.map(
              array.mapWithIndex((index, autocompleteItem) => {
                const isHighlighted = highlightedIndex === index

                const acText = autocompleteItem.queryPlusText

                return (
                  <li
                    {...getItemProps({
                      index,
                      item: acText,
                      key: `${autocompleteItem}${index}`,
                    })}
                    className={tw(`px(4 sm:2) py(2 sm:1)`, isHighlighted && `bg-gray-200`)}
                  >
                    {acText}
                  </li>
                )
              })
            ),
            option.getOrElseW(() => <></>)
          )}
      </ul>
    </form>
  )
}
