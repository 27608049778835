import { GoogleAnalyticsHead } from "app/common/components/GoogleAnalyticsHead"
import { InstagramEmbedScript } from "app/common/components/InstagramEmbedScript"
import { SeoEssentials } from "app/common/components/seo/SeoEssentials"
import { Spinner } from "app/common/components/Spinner"
import { TwitterEmbedScript } from "app/common/components/TwitterEmbedScript"
import { getOrUndefined } from "app/common/fp-ts/either"
import { RouteMgr } from "app/common/routes"
import { SeoMetadata } from "app/common/seo/seoEssentials"
import { BlogFooter } from "app/layouts/components/BlogFooter"
import { SearchForm } from "app/layouts/components/SearchForm"
import { BlitzRouter, Head, Link, RouteUrlObject, useRouter } from "blitz"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"
import useTranslation from "next-translate/useTranslation"
import React, { ReactNode, Suspense } from "react"
import { FaCanadianMapleLeaf } from "react-icons/fa"
import { apply, tw } from "twind"

type LayoutProps = {
  children: ReactNode
}

const GoogleAnalyticsConfig = t.string

export const containerWidth = apply`w-full max-w-7xl mx-auto`

const isRoute = (routeObject: RouteUrlObject | string) => (router: BlitzRouter) =>
  typeof routeObject === "string"
    ? routeObject === router.pathname
    : routeObject.pathname === router.pathname

const BlogLayout = ({ children }: LayoutProps) => {
  const { t } = useTranslation("common")

  const router = useRouter()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const seoMetadata: SeoMetadata = (children as any).props.seoMetadata

  return (
    <>
      <>
        <Head>
          <link href="/favicon.svg" rel="icon" />
        </Head>
        {seoMetadata && <SeoEssentials {...seoMetadata} />}
      </>

      <InstagramEmbedScript />
      <TwitterEmbedScript />

      {pipe(
        GoogleAnalyticsConfig.decode(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID),
        getOrUndefined((NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID) => (
          <GoogleAnalyticsHead
            key="ga"
            measurementId={NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}
          />
        ))
      )}

      <div className={tw(`flex(& col) min-h-screen`)}>
        <header
          className={tw(
            `bg-white`,
            `border(b-1 gray-300)`,
            `fixed sm:static top-0 left-0 right-0`,
            `px(3 sm:8) py-4`,
            `shadow(md sm:none)`,
            `z-30`
          )}
        >
          <div className={tw(containerWidth, `flex justify-between items-center gap-12`)}>
            <div className={tw(`select-none`)}>
              <Link href={RouteMgr.Home() || "/"}>
                <a className={tw(`flex(& row) gap-2 items-center`, `text(red-600 2xl) font-bold`)}>
                  <FaCanadianMapleLeaf className={tw(`inline align-sub`)} />
                  Norther.
                </a>
              </Link>
              <span className={tw(`text-xs`, `text-gray-600`)}>{t("buy-local-buy-canadian")}</span>
            </div>
            <ul className={tw(`flex gap-6`, `font-semibold`, `ml-auto`, `select-none`)}>
              {!isRoute(RouteMgr.Home() || "/")(router) && (
                <li>
                  <Link href={RouteMgr.Home() || "/"}>
                    <a className={tw(`blogLink`)}>{t("home")}</a>
                  </Link>
                </li>
              )}
              <li>
                <Link href={RouteMgr.CategoryPage()}>
                  <a className={tw(`blogLink`)}>{t("categories")}</a>
                </Link>
              </li>
            </ul>
            <SearchForm />
          </div>
        </header>

        <main className={tw(`flex-1`, `px(3 sm:8) pt(28 sm:8)`)}>
          <div className={tw(containerWidth)}>
            <Suspense fallback={<Spinner />}>{children}</Suspense>
          </div>
        </main>

        <BlogFooter url={seoMetadata?.urlMap} />
      </div>
    </>
  )
}

BlogLayout.getLayout = (page: JSX.Element) => <BlogLayout>{page}</BlogLayout>

export default BlogLayout
