import useTranslation from "next-translate/useTranslation"
import { tw } from "twind"

export const NortherDefinition = () => {
  const { t } = useTranslation("common")

  return (
    <div className={tw(`text-sm`)}>
      <div className={tw(`font-bold`)}>north·er</div>
      <div className={tw(`font-semibold`)}>/ˈnôrT͟Hər/</div>
      <div>&bull; {t("norther-definition")}</div>
    </div>
  )
}
