import { NortherDefinition } from "app/common/components/NortherDefinition"
import { RouteMgr } from "app/common/routes"
import { containerWidth } from "app/layouts/BlogLayout"
import { LocaleToggle } from "app/layouts/components/LocaleToggle"
import { Link } from "blitz"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"
import { tw } from "twind"

interface BlogFooterProps {
  url: { en_ca: string; fr_ca: string }
}

export const BlogFooter: FC<BlogFooterProps> = ({ url }) => {
  const { t } = useTranslation("common")

  return (
    <footer
      className={tw(
        `text(3xl gray-600)`,
        `bg-gray-200`,
        `mb(10 sm:0) mt-20`,
        `px(3 sm:8) pt-10 pb(20 md:10)`
      )}
    >
      <div className={tw(containerWidth, `flex(& col) gap-12`)}>
        <div className={tw(`flex(& col sm:row) gap-8 justify-between items(stretch sm:start)`)}>
          <div className={tw(`flex(& col) gap-6`)}>
            <NortherDefinition />
            <div className={tw(`text-sm`, `max-w-sm`)}>{t("common-about-us")}</div>
          </div>
          <ul
            className={tw(
              `flex(& row) items-center justify(between sm:end) gap(6 sm:12)`,
              `text(base sm:lg)`,
              `children:hover:text-blue-500`
            )}
          >
            <li>
              <Link href={RouteMgr.About()}>
                <a>{t(`about-us`)}</a>
              </Link>
            </li>
            <li>
              <Link href={RouteMgr.Contact()}>
                <a>{t(`contact-us`)}</a>
              </Link>
            </li>
            <li>
              <Link href={RouteMgr.TermsOfUse()}>
                <a>{t(`terms-of-use`)}</a>
              </Link>
            </li>
            <li>
              <Link href={RouteMgr.PrivacyPolicy()}>
                <a>{t(`privacy-policy`)}</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={tw(`flex(& row) justify-between items-end`)}>
          {url && <LocaleToggle url={url} />}
          <div className={tw(`text(sm center)`)}>&copy; Norther.ca</div>
        </div>
      </div>
    </footer>
  )
}
