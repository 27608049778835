import { getOrFalse } from "app/common/fp-ts/either"
import { predicate } from "fp-ts"
import { pipe } from "fp-ts/function"
import { keyof, strict, TypeOf } from "io-ts"

const production = "production"

const development = "development"

const NodeEnv = keyof({ [development]: null, [production]: null })

const NodeConfig = strict({
  NODE_ENV: NodeEnv,
})

const getIsProduction: predicate.Predicate<TypeOf<typeof NodeConfig>> = ({ NODE_ENV }) =>
  NODE_ENV === production

const nodeConfig = NodeConfig.decode(process.env)

export const isProduction = pipe(nodeConfig, getOrFalse(getIsProduction))
