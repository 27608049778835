import { LocaleD } from "db"
import { taskEither } from "fp-ts"

// ts-prune-ignore-next
export type Unpromise<T> = T extends PromiseLike<infer U> ? U : T

// ts-prune-ignore-next
export type Unarray<T> = T extends Array<infer U> ? U : T

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }

// ts-prune-ignore-next
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U

type UnionKeys<T> = T extends T ? keyof T : never

// Improve intellisense
type Expand<T> = T extends T ? { [K in keyof T]: T[K] } : never

// eslint-disable-next-line @typescript-eslint/ban-types
export type OneOf<T extends {}[]> = {
  [K in keyof T]: Expand<T[K] & Partial<Record<Exclude<UnionKeys<T[number]>, keyof T[K]>, never>>>
}[number]

export type ValueOf<T> = T[keyof T]

export const CanadianLocales = {
  En: "en-ca",
  Fr: "fr-ca",
} as const

export type CanadianLocale = ValueOf<typeof CanadianLocales>

export const localeToCanadianLocale = (locale: LocaleD): CanadianLocale =>
  locale === LocaleD.fr_ca ? CanadianLocales.Fr : CanadianLocales.En

export type UnTaskEither<T> = T extends taskEither.TaskEither<Error, infer U> ? U : T

type Identifiable = { id: number }

export const selectId = <T extends Identifiable>(hasId: T) => hasId.id
