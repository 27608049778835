import { formatErrors } from "app/common/schema/formatErrors"
import { either } from "fp-ts"
import { constFalse, constUndefined, pipe } from "fp-ts/function"
import { identity } from "io-ts"
import * as t from "io-ts"

export const getOrFalse = <A>(onRight: (a: A) => boolean) => either.fold(constFalse, onRight)

export const getOrUndefined = <A, B>(onRight: (a: A) => B) => either.fold(constUndefined, onRight)

export const getOrThrow = <A, E extends t.Errors>(e: either.Either<E, A>) =>
  pipe(
    e,
    either.fold((errors) => {
      throw formatErrors(errors)
    }, identity)
  )
