import { Head } from "blitz"
import { FC } from "react"

interface CanonicalUrlProps {
  href: string
}

export const CanonincalUrl: FC<CanonicalUrlProps> = ({ href }) => (
  <Head>
    <link href={href} rel="canonical" />
  </Head>
)
