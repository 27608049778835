import { Head } from "blitz"
import { FC } from "react"

interface MetaDescriptionProps {
  content: string
}

export const MetaDescription: FC<MetaDescriptionProps> = ({ content }) => (
  <Head>
    <meta content={content} key="meta-description" name="description" />
  </Head>
)
