import * as t from "io-ts"

export const FilterNames = {
  category: "category",
  region: "region",
  stockist: "stockist",
  tag: "tag",
} as const

export const FilterNameC = t.keyof(FilterNames)

export type FilterName = t.TypeOf<typeof FilterNameC>
