import { kebabToSnake } from "app/common/utilities/string"
import { LocaleD } from "db"
import { either, refinement } from "fp-ts"
import { flow, pipe } from "fp-ts/function"
import { failure, string, success, Type } from "io-ts"

const isLocaleD: refinement.Refinement<unknown, LocaleD> = (u): u is LocaleD =>
  pipe(LocaleD, Object.values, (arr) => arr.includes(u))

export const LocaleDC = new Type<LocaleD, string>(
  "LocaleDC",
  isLocaleD,
  (u, c) =>
    pipe(
      string.validate(u, c),
      either.chain(flow(kebabToSnake, (l) => (isLocaleD(l) ? success(l) : failure(u, c))))
    ),
  String
)
