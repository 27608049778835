import { AlternateLinks } from "app/common/components/seo/AlternatetLinks"
import { CanonincalUrl } from "app/common/components/seo/CanonincalUrl"
import { MetaDescription } from "app/common/components/seo/MetaDescription"
import { OpenGraph } from "app/common/components/seo/OpenGraph"
import { Title } from "app/common/components/seo/Title"
import { SeoMetadata } from "app/common/seo/seoEssentials"
import { Script } from "blitz"
import { FC } from "react"

type SeoEssentialsProps = SeoMetadata

export const SeoEssentials: FC<SeoEssentialsProps> = ({
  canonicalUrl,
  ldJsonArticle,
  metaDescription,
  openGraphMetadata,
  title,
  urlMap,
}) => {
  return (
    <>
      <Title title={title} />
      <AlternateLinks url={urlMap} />
      <CanonincalUrl href={canonicalUrl} />
      <OpenGraph {...openGraphMetadata} />
      {metaDescription && <MetaDescription content={metaDescription} />}
      {ldJsonArticle && (
        <Script
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonArticle) }}
          key="product-jsonld"
          strategy="afterInteractive"
          type="application/ld+json"
        />
      )}
    </>
  )
}
