/* eslint-disable react/jsx-sort-props */
import { OgType, OpenGraphMetadata } from "app/common/seo/openGraph"
import { localeToCanadianLocale } from "app/common/utilities/types"
import { Head } from "blitz"
import { option } from "fp-ts"
import { pipe } from "fp-ts/function"
import { FC } from "react"

type OpenGraphProps = OpenGraphMetadata

export const OpenGraph: FC<OpenGraphProps> = ({
  description,
  locale,
  siteName,
  title,
  type,
  url,
}) => (
  <Head>
    <meta property="og:description" content={description} />
    <meta property="og:locale" content={localeToCanadianLocale(locale.locale)} />
    <meta property="og:locale:alternate" content={localeToCanadianLocale(locale.alternate)} />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:title" content={title} />
    <meta
      property="og:type"
      content={pipe(
        type,
        option.getOrElse(() => OgType.Website)
      )}
    />
    <meta property="og:url" content={url} />
  </Head>
)
