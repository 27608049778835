export const joinWith =
  (separator: string) =>
  <T>(array: T[]) =>
    array.join(separator)

export const joinWithSlash = joinWith("/")

export const joinWithSpace = joinWith(" ")

export const joinWithCommaSpace = joinWith(", ")

export const joinWithNewline = joinWith("\n")

export const joinWithSemicolon = joinWith(";")

const splitOn = (separator: string) => (str: string) => str.split(separator)

export const splitOnSlash = splitOn("/")

export const includes = (s: string) => (a: string[]) => a.includes(s)
