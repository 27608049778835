import { endomorphism, predicate, string } from "fp-ts"
import { pipe } from "fp-ts/function"

export const collapseSpaces: endomorphism.Endomorphism<string> = (str) => str.replace(/\s{2,}/, " ")

// ts-prune-ignore-next
export const snakeToKebab: endomorphism.Endomorphism<string> = (str) => str.replace("_", "-")

export const kebabToSnake: endomorphism.Endomorphism<string> = (str) => str.replace("-", "_")

export const replaceWith =
  (searchValue: string | RegExp) => (replaceValue: string) => (str: string) =>
    pipe(str, string.replace(new RegExp(searchValue, "g"), replaceValue))

export const isNumeric: predicate.Predicate<unknown> = (num) => !isNaN(+(num as string))

export const isNonNumeric = predicate.not(isNumeric)
