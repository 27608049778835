import { FilterName, FilterNameC, FilterNames } from "app/search/codecs/FilterNameC"
import { either, predicate } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"

export type StringEqualsFilter = { field: FilterName; value: string }

export const StringEqualsFilterC = new t.Type<StringEqualsFilter, string>(
  "StringEqualsFilterC",
  (u): u is StringEqualsFilter =>
    t.string.is((u as StringEqualsFilter)?.field) && t.string.is((u as StringEqualsFilter)?.value),
  (u, c) =>
    pipe(
      t.string.is(u) && u.split("==").length === 2
        ? pipe(
            u.split("==")[0] ?? "",
            (field) => FilterNameC.validate(field, c),
            either.chain((fn) =>
              t.success({
                field: fn,
                value: u.split("==")[1] ?? "",
              })
            )
          )
        : t.failure(u, c)
    ),
  (a) => `${a.field}==${a.value}`
)

export const createCategoryFilter = (str: string): StringEqualsFilter => ({
  field: FilterNames.category,
  value: str,
})

export const isNotCategoryFilter: predicate.Predicate<StringEqualsFilter> = (filter) =>
  filter.field !== FilterNames.category
