import { array, endomorphism, monoid, option, predicate, refinement, semigroup } from "fp-ts"
import { apply, flow, getMonoid, pipe } from "fp-ts/function"

/**
 * Runs the provided morphism on the input value if the predicate fails.
 *
 * @example
 * import { unless } from 'fp-ts-std/Function';
 * import { increment } from 'fp-ts-std/Number';
 * import { Predicate } from 'fp-ts/Predicate';
 *
 * const isEven: Predicate<number> = n => n % 2 === 0;
 * const ensureEven = unless(isEven)(increment);
 *
 * assert.strictEqual(ensureEven(1), 2);
 * assert.strictEqual(ensureEven(2), 2);
 *
 * @since 0.6.0
 */
// ts-prune-ignore-next
export const unless =
  <A>(f: predicate.Predicate<A>) =>
  (onFalse: endomorphism.Endomorphism<A>): endomorphism.Endomorphism<A> =>
  (x) =>
    f(x) ? x : onFalse(x)

/**
 * Runs the provided morphism on the input value if the predicate holds.
 *
 * @example
 * import { when } from 'fp-ts-std/Function';
 * import { increment } from 'fp-ts-std/Number';
 * import { Predicate } from 'fp-ts/Predicate';
 *
 * const isEven: Predicate<number> = n => n % 2 === 0;
 * const ensureOdd = when(isEven)(increment);
 *
 * assert.strictEqual(ensureOdd(1), 1);
 * assert.strictEqual(ensureOdd(2), 3);
 *
 * @since 0.6.0
 */
export const when: <A>(
  f: predicate.Predicate<A>
) => (onTrue: endomorphism.Endomorphism<A>) => endomorphism.Endomorphism<A> = flow(
  predicate.not,
  unless
)

/**
 * Creates a function that processes the first morphism if the predicate
 * succeeds, else the second morphism.
 *
 * @example
 * import { ifElse } from 'fp-ts-std/Function';
 * import { increment, decrement } from 'fp-ts-std/Number';
 * import { Predicate } from 'fp-ts/Predicate';
 *
 * const isPositive: Predicate<number> = n => n > 0;
 * const normalise = ifElse(decrement)(increment)(isPositive);
 *
 * assert.strictEqual(normalise(-3), -2);
 * assert.strictEqual(normalise(3), 2);
 *
 * @since 0.6.0
 */
// ts-prune-ignore-next
export const ifElse =
  <A, B>(onTrue: (x: A) => B) =>
  (onFalse: (x: A) => B) =>
  (f: predicate.Predicate<A>) =>
  (x: A): B =>
    f(x) ? onTrue(x) : onFalse(x)

/**
 * Given an array of predicates and morphisms, returns the first morphism output
 * for which the paired predicate succeeded. If all predicates fail, the
 * fallback value is returned.
 *
 * This is analagous to Haskell's guards.
 *
 * @example
 * import { guard } from 'fp-ts-std/Function';
 * import { constant } from 'fp-ts/function';
 *
 * const numSize = guard<number, string>([
 *     [n => n > 100, n => `${n} is large!`],
 *     [n => n > 50, n => `${n} is medium.`],
 *     [n => n > 0, n => `${n} is small...`],
 * ])(n => `${n} is not a positive number.`);
 *
 * assert.strictEqual(numSize(101), '101 is large!');
 * assert.strictEqual(numSize(99), '99 is medium.');
 * assert.strictEqual(numSize(5), '5 is small...');
 * assert.strictEqual(numSize(-3), '-3 is not a positive number.');
 *
 * @since 0.6.0
 */
// ts-prune-ignore-next
export const guard =
  <A, B>(branches: Array<[predicate.Predicate<A>, (x: A) => B]>) =>
  (fallback: (x: A) => B) =>
  (input: A): B =>
    pipe(
      branches,
      array.map(([f, g]) => flow(option.fromPredicate(f), option.map(g))),
      monoid.concatAll(getMonoid(option.getMonoid<B>(semigroup.first()))<A>()),
      apply(input),
      option.getOrElse(() => fallback(input))
    )

/**
 * Yields the result of applying the morphism to the input until the predicate
 * holds.
 *
 * @example
 * import { until } from 'fp-ts-std/Function';
 * import { increment } from 'fp-ts-std/Number';
 * import { Predicate } from 'fp-ts/Predicate';
 *
 * const isOver100: Predicate<number> = n => n > 100;
 * const doubleUntilOver100 = until(isOver100)(n => n * 2);
 *
 * assert.strictEqual(doubleUntilOver100(1), 128);
 *
 * @since 0.6.0
 */
// ts-prune-ignore-next
export const until =
  <A>(f: predicate.Predicate<A>) =>
  (g: endomorphism.Endomorphism<A>): endomorphism.Endomorphism<A> => {
    const h: endomorphism.Endomorphism<A> = (x) => (f(x) ? x : h(g(x)))

    return h
  }

/**
 * A curried function equivalent to the `instanceof` operator, for when you
 * absolutely must test a prototype.
 *
 * @example
 * import { is } from 'fp-ts-std/Function';
 *
 * const isString = is(String);
 *
 * assert.strictEqual(isString('ciao'), false);
 * assert.strictEqual(isString(new String('ciao')), true);
 *
 * @since 0.12.0
 */
export const is =
  <A>(x: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new (...args: Array<any>): unknown
  }): refinement.Refinement<unknown, A> =>
  (y: unknown): y is A =>
    y instanceof x
