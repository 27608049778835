import { Head } from "blitz"
import { FC } from "react"

interface TitleProps {
  title: string
}

export const Title: FC<TitleProps> = ({ title }) => (
  <Head>
    <title>{title}</title>
  </Head>
)
