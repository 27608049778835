import { joinWithSemicolon } from "app/common/fp-ts/array"
import { array, either, predicate } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as t from "io-ts"

export function withDelimited<C extends t.Any>(codec: C, name = `withDelimited(${codec.name})`) {
  const arr = t.array(codec)

  return new t.Type<Array<t.TypeOf<C>>, string, string>(
    name,
    (u): u is Array<t.TypeOf<C>> => pipe(u, predicate.and(Array.isArray)(array.every(codec.is))),
    (u, c) =>
      pipe(
        t.string.validate(u, c),
        either.chain((s) => pipe(s.split(";"), array.map(codec.decode), array.rights, t.success))
      ),
    (a) => pipe(arr.encode(a), joinWithSemicolon)
  )
}
