import { UrlMap } from "app/common/seo/seoEssentials"
import { Head } from "blitz"
import { array, record } from "fp-ts"
import { pipe } from "fp-ts/function"
import { FC } from "react"

const hrefLangMap: Record<keyof UrlMap, string> = {
  en_ca: "en",
  fr_ca: "fr",
  "x-default": "x-default",
} as const

interface AlternateLinksProps {
  url: UrlMap
}

export const AlternateLinks: FC<AlternateLinksProps> = ({ url }) => (
  <Head>
    {pipe(
      url,
      record.toEntries,
      array.map(([hrefLang, href]) => (
        <link href={href} hrefLang={hrefLangMap[hrefLang]} key={hrefLang} rel="alternate" />
      ))
    )}
  </Head>
)
