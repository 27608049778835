import { LocaleDC } from "app/common/decoders/locale"
import { localeToCanadianLocale } from "app/common/utilities/types"
import { Link, useRouter } from "blitz"
import { LocaleD } from "db"
import { array, either, nonEmptyArray } from "fp-ts"
import { pipe } from "fp-ts/function"
import React, { FC } from "react"
import { IconType } from "react-icons"
import { FaCanadianMapleLeaf } from "react-icons/fa"
import { GiFleurDeLys } from "react-icons/gi"
import { tw } from "twind"

type LocaleInfo = {
  Icon: IconType
  locale: LocaleD
  name: "English" | "Français"
}

const en: LocaleInfo = { Icon: FaCanadianMapleLeaf, locale: LocaleD.en_ca, name: "English" }

const fr: LocaleInfo = { Icon: GiFleurDeLys, locale: LocaleD.fr_ca, name: "Français" }

const items: nonEmptyArray.NonEmptyArray<LocaleInfo> = [en, fr]

const itemToString = (l: LocaleInfo) => l.name

interface LocaleToggleProps {
  url: Record<LocaleD, string>
}

export const LocaleToggle: FC<LocaleToggleProps> = ({ url }) => {
  const router = useRouter()

  const currentLocaleInfo = pipe(
    router.locale,
    LocaleDC.decode,
    either.chainW((l1) =>
      pipe(
        items,
        array.findFirst((l2) => l2.locale === l1),
        either.fromOption(() => new Error(""))
      )
    ),
    either.getOrElse(() => nonEmptyArray.head(items))
  )

  return (
    <ul className={tw(`flex gap-1`, `text-lg`)}>
      {items.map((item, index) => {
        const isCurrentLocale = currentLocaleInfo.locale === item.locale

        const color = isCurrentLocale ? "red" : "gray"

        const weight = isCurrentLocale ? "500" : "400"

        return (
          <li key={`${item}${index}`}>
            <Link href={url[item.locale]} locale={localeToCanadianLocale(item.locale)}>
              <a
                className={tw(
                  `flex gap-2 items-center`,
                  `border(1 ${color}-${weight}) text-${color}-${weight}`,
                  `px-4 py-2`
                )}
              >
                <item.Icon />
                {itemToString(item)}
              </a>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
